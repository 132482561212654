// _typography.scss

body {
  font-family: var(--body-font-family, $idiot-font-stack);
  font-size: var(--body-font-size, $base-font-size);
  font-weight: 500;
  line-height: var(--body-line-height, 1.5);
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font-family, $idiot-font-stack);
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--heading-color);
}

h1 { font-size: var(--h1-font-size, #{$h1-font-size}); }
h2 { font-size: var(--h2-font-size, #{$h2-font-size}); }
h3 { font-size: var(--h3-font-size, #{$h3-font-size}); }
h4 { font-size: var(--h4-font-size, #{$h4-font-size}); }
h5 { font-size: var(--h5-font-size, #{$h5-font-size}); }
h6 { font-size: var(--h6-font-size, #{$h6-font-size}); }

.t__size-xxl { font-size: var(--font-size-xxl, 140pt); line-height: 1.1; }
.t__size-xl { font-size: var(--font-size-xl, 100pt); line-height: 1.2; }
.t__size-l { font-size: var(--font-size-l, 84pt); line-height: 1.2; }
.t__size-m { font-size: var(--font-size-m, 56pt); line-height: 1.3; }
.t__size-s { font-size: var(--font-size-s, 28pt); line-height: 1.4; }
.t__size-xs { font-size: var(--font-size-xs, 14pt); line-height: 1.4; }

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.lead {
  font-size: var(--lead-font-size, 1.25rem);
  font-weight: 400;
}

.text-small {
  color: var(--text-color-light);
  font-size: var(--font-size-small, 0.8em);
  text-transform: uppercase;
}

.text-large {
  font-size: var(--large-font-size, 1.25em);
}

.text-uppercase { text-transform: uppercase; }
.text-lowercase { text-transform: lowercase; }
.text-capitalize { text-transform: capitalize; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.font-weight-light { font-weight: 400; }
.font-weight-normal { font-weight: 500; }
.font-weight-bold { font-weight: 700; }

.font-italic { font-style: italic; }

.text-primary { color: var(--primary-color); }
.text-secondary { color: var(--secondary-color); }
.text-success { color: var(--success-color); }
.text-warning { color: var(--warning-color); }
.text-danger { color: var(--error-color); }

// Add more typography styles as needed