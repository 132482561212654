@media print {
	// Reset styles
	* {
	  background: transparent !important;
	  color: #000;
	  box-shadow: none !important;
	  text-shadow: none !important;
	  filter: none !important;
	  -ms-filter: none !important;
	}
  
	body {
	  margin: 0;
	  padding: 0;
	  line-height: 1.4;
	  font-size: 12pt;
	  background: none;
	}
  
	// Layout adjustments
	.canvas .off-canvas-content,
	.canvas,
	body,
	html {
	  height: auto !important;
	  width: 100% !important;
	  overflow: visible !important;
	}

	// Override container styles
	.container,
	.container-fluid,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		width: 100% !important;
		max-width: none !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	// Ensure full-width for common layout elements
	.row,
	.col,
	[class^="col-"] {
		width: 100% !important;
		max-width: none !important;
		flex: 0 0 100% !important;
	}
  

	// Content styles
	.item {
	  break-inside: avoid;
	  page-break-inside: avoid;
	  width: 100% !important;
	  max-width: 100% !important;
	  padding: 0 !important;
	  margin: 0 !important;
	}
  
	.item__proof {
	  box-shadow: none !important;
	  border: none !important;
	  padding: 0 !important;
	  width: 100% !important;
	  max-width: 100% !important;
	transform: none !important;
	}
  
	// Hide unnecessary elements
	#section__header-stage-buttons,
	.navbar,
	.item__sliders,
	#section__footer-app,
	button,
	.btn,
	.no-print {
	  display: none !important;
	}
  
	// Typography adjustments
	h1, h2, h3, h4, h5, h6 {
	  page-break-after: avoid;
	  page-break-inside: avoid;
	}
  
	h1 { font-size: 24pt; }
	h2 { font-size: 20pt; }
	h3 { font-size: 16pt; }
	h4, h5, h6 { font-size: 10pt; margin-bottom: 0; }
  
	p, h2, h3 {
	  orphans: 3;
	  widows: 3;
	}

	h6, .item__proof .testarea-values, .proof-footer {
		font-size: 8pt !important;
		color: #999 !important;
	}
  
	// Links
	a, a:visited {
	  text-decoration: underline;
	}
  
	a[href]:after {
	  content: " (" attr(href) ")";
	}
  
	a[href^="#"]:after,
	a[href^="javascript:"]:after {
	  content: "";
	}
  
	// Tables
	thead {
	  display: table-header-group;
	}
  
	tr, img {
	  page-break-inside: avoid;
	}
  
	// Page settings
	@page {
	  padding: 4mm 5mm;
	}
  
	// Ensure last item doesn't force an extra blank page
	.item:last-child {
	  page-break-after: auto;
	}
  }