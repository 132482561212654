// _layout.scss
html * {
	box-sizing: border-box;
}
#section__article-app.container {
    max-width: 700px;
	margin: 0 auto;
	body.tools-visible & {
		max-width: calc(700px + 200px + var(--spacing-lg));
	}
}
// Canvas and sidebars
.canvas {
	display: flex;
	box-sizing: border-box;
	flex-flow: nowrap;
	width: 100%;
	min-height: 100vh;
	transition: all .25s ease-in-out;
	overflow-x: hidden;
  
	.off-canvas-sidebar-fonts {
	  flex: 0 0 auto;
	  max-width: 0px;
	  min-width: 0px;
	  opacity: 0;
	  overflow: hidden;
	  position: sticky;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  height: 100%;
	  max-height: 100vh;
	  transition: width 0.25s ease;
	  z-index: 200;
	  scrollbar-width: thin;
	  scrollbar-color: var(--border-color) var(--background-color);
	  overflow-y: auto;
	  height: 100vh;

	  body.tools-visible & {
		min-width: 11.5rem;
		max-width: auto;
		overflow-y: auto;
		opacity: 1;
	  }
  
	  @media (max-width: #{$breakpoint-lg}) {
		body.tools-visible & {
			max-width: 50px;
		}
	  }
	}
  
	.off-canvas-content {
	  flex: 1;
	  min-width: 0;
	  overflow-y: auto;
	  height: 100vh;
	  transition: all 0.25s ease;
	  min-height: 100%;
	}

	.off-canvas-sidebar-settings {
		flex: 0 0 auto;
		max-width: 0px;
		min-width: 0px;
		overflow: hidden;
		position: sticky;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		transition: transform 0.25s ease;
		z-index: 201;
		scrollbar-color: var(--border-color) var(--background-color);
		overflow-y: auto;
		height: 100vh;

		body.settings-visible & {
			overflow-y: auto;
			min-width: 11.5rem;
			max-width: auto;
		}
	}
  }
  
  // Section header
  .section__header-app {
	background: var(--header-bg, #061117);
	color: var(--header-color, #4d494d);
	position: sticky;
	top: 0;
	z-index: var(--z-index-header, 100);
	padding-bottom: 0;
  
  }
  
  // Section footer
  .section__footer-app {
	a {
	  color: var(--footer-link-color, #e47f7d);
	}
  }


// Tab styles
.tab {
	border-bottom: .05rem solid var(--border-color);
	display: flex;
	flex-wrap: none;
	list-style: none;
	margin: 0px;
  
	&.tab-block {
	  .tab-item {
		flex: 1 0 auto;
		text-align: center;
	  }
	}
  
	.tab-item {
	  margin-top: 0;
  
	  a {
		border-bottom: 2px solid transparent;
		color: var(--text-color);
		display: block;
		margin: 0;
		margin-bottom:-2px;
		padding: var(--idiot-unit) calc(var(--idiot-unit) * 2);
		text-decoration: none;
		transition: all var(--transition-duration, 0.3s) ease;
  
		&:focus,
		&:hover {
		  color: var(--link-color);
		}
	  }
  
	  &.active a {
		border-bottom-color: var(--link-color);
		color: var(--link-color);
	  }
	}
  }
  
  #section__header-stage-buttons {
	background-color: var(--background-color);
	border-bottom: 1px solid var(--border-color);
  
	.tab-item {
	  .stage-button {
		font-weight: var(--font-weight-normal, 400);
		font-size: var(--base-font-size);
	  }
  
	  &.active .stage-button {
		font-weight: var(--font-weight-bold, 700);
	  }
	}
  }
  
  .drag-drop-area {
	height: 92vh;
	border: 2px dashed var(--button-bg);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	text-align: center;
	transition: all 0.3s ease;
  }
  
  .drag-drop-area.highlight {
	border: 2px solid var(--border-color);
	background-color: var(--input-bg);
  }
  
  .drag-drop-area p {
	margin: var(--spacing-sm) 0;
	color: var(--text-color);
  }
  
  .file-input-label {
	padding: var(--spacing-xs) var(--spacing-sm);
	border-radius: var(--spacing-xs);
	background-color: var(--input-bg);
	border: 2px solid var(--input-bg);
  }
  
  .file-input-label:hover {
	background-color: var(--input-bg);
	border: 2px solid var(--border-color);
  }
  
  .file-input {
	display: none;
  }
  
  .drag-drop-area img {
	max-width: 50px;
	max-height: 50px;
	margin: 10px;
	border-radius: 5px;
  }
