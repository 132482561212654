/* Define the "system" font family */
@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}
@font-face {
  font-family: "VC Nudge Text";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/VCNudgeText-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "VC Nudge Text";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/VCNudgeText-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "VC Nudge Text";
  font-style: bold;
  font-weight: 700;
  src: url("../fonts/VCNudgeText-Bold.woff2") format("woff2");
}
