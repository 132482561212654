@charset "UTF-8";
@CHARSET "UTF-8";
:root {
  --idiot-unit: 8px;
  --spacing-xs: calc(var(--idiot-unit) * 0.5);
  --spacing-sm: var(--idiot-unit);
  --spacing-md: calc(var(--idiot-unit) * 2);
  --spacing-lg: calc(var(--idiot-unit) * 3);
  --spacing-xl: calc(var(--idiot-unit) * 4);
  --spacing-xxl: calc(var(--idiot-unit) * 8);
  --idiot-white: #f9f4f2;
  --idiot-black: #061117;
  --idiot-yellow: #efba16;
  --idiot-yellow-dark: #e2af13;
  --idiot-orange: #f24b1f;
  --idiot-orange-dark: #fb3600;
  --idiot-pink: #e47f7d;
  --idiot-blue: #2997ff;
  --idiot-blue-dark: #267ac9;
  --idiot-darkblue: #14374a;
  --idiot-lighter-grey: #e6dedb;
  --idiot-light-grey: #dcd4d0;
  --idiot-grey: #9d9897;
  --idiot-dark-grey: #726c6a;
  --idiot-darker-grey: #343231;
  --idiot-darkest-grey: #1d1d1f;
  --primary-color: var(--idiot-orange);
  --primary-color-dark: var(--idiot-orange-dark);
  --background-color: var(--idiot-white);
  --text-color: var(--idiot-black);
  --text-color-light: var(--idiot-dark-grey);
  --text-color-muted: var(--idiot-grey);
  --heading-color: var(--idiot-black);
  --link-color: var(--primary-color);
  --link-hover-color: var(--primary-color-dark);
  --button-bg: var(--idiot-lighter-grey);
  --button-text: var(--idiot-black);
  --button-hover-bg: var(--primary-color-dark);
  --button-hover-text: var(--primary-color);
  --chip-bg: var(--idiot-lighter-grey);
  --chip-active-bg: var(--idiot-orange);
  --chip-text: var(--idiot-black);
  --input-bg: #fff;
  --input-border: #bcc3ce;
  --input-focus-border: var(--idiot-orange);
  --input-placeholder: #bcc3ce;
  --slider-bg: var(--idiot-lighter-grey);
  --slider-thumb-color: var(--idiot-light-grey);
  --slider-focus-color: rgba(251, 54, 0, 0.2);
  --slider-active-color: var(--primary-color-dark);
  --slider-disabled-color: #bcc3ce;
  --card-bg: #fff;
  --card-shadow: 0 6px 24px var(--idiot-lighter-grey);
  --border-color: #dadee4;
  --tooltip-bg: rgba(48, 55, 66, 0.95);
  --tooltip-text: #fff;
  --table-border: #dadee4;
  --table-stripe: #f7f8f9;
  --success-color: #32b643;
  --warning-color: var(--idiot-orange);
  --error-color: #e85600;
}

[data-theme=dark] {
  color-scheme: dark;
  --primary-color:var(--idiot-blue);
  --primary-color-dark:var(--idiot-blue-dark);
  --background-color: var(--idiot-darkest-grey);
  --text-color: var(--idiot-white);
  --text-color-light: var(--idiot-grey);
  --heading-color: var(--idiot-white);
  --border-color: #424245;
  --link-color: var(--primary-color);
  --button-bg: var(--idiot-darker-grey);
  --button-text: var(--idiot-light-grey);
  --button-hover-bg: var(--primary-color-dark);
  --button-hover-text: var(--primary-color);
  --chip-bg: #3a3a3c;
  --chip-active-bg: var(--primary-color);
  --chip-text: #f5f5f7;
  --input-bg: #1c1c1e;
  --input-border: #424245;
  --input-text: #f5f5f7;
  --slider-bg: #424245;
  --slider-thumb-color: #f5f5f7;
  --slider-focus-color: rgba(245, 245, 247, 0.2);
  --slider-active-color: var(--primary-color);
  --slider-disabled-color: #636366;
  --sidebar-bg: #2c2c2e;
  --header-bg: #1c1c1e;
  --card-bg: #2c2c2e;
  --card-shadow: 0 6px 24px var(--idiot-black);
  --disabled-bg: #3a3a3c;
  --disabled-text: #8e8e93;
  --hover-bg: #3a3a3c;
  --active-bg: #515154;
  --tooltip-bg: #636366;
  --tooltip-text: #f5f5f7;
  --scrollbar-bg: #1c1c1e;
  --scrollbar-thumb: #424245;
  --code-bg: #2c2c2e;
  --code-text: #ff375f;
  --table-header-bg: #2c2c2e;
  --table-row-hover: #3a3a3c;
  --success-color: #30d158;
  --warning-color: var(--idiot-blue);
  --error-color: #ff453a;
}

/* Define the "system" font family */
@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}
@font-face {
  font-family: "VC Nudge Text";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/VCNudgeText-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "VC Nudge Text";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/VCNudgeText-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "VC Nudge Text";
  font-style: bold;
  font-weight: 700;
  src: url("../fonts/VCNudgeText-Bold.woff2") format("woff2");
}
html {
  font-size: 20px;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: 1.5;
  text-rendering: optimizeLegibility;
}

a {
  color: var(--link-color);
  text-decoration: none;
  outline: 0;
}
a:hover, a:focus {
  color: var(--link-hover-color);
  text-decoration: underline;
}

.btn {
  background-color: var(--button-bg);
  color: var(--button-text);
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  align-items: center;
  user-select: none;
  border: 1px solid transparent;
  padding: var(--spacing-xs) var(--spacing-sm);
  gap: var(--spacing-xs);
  font-size: var(--btn-font-size, 0.6rem);
  font-family: var(--idiot-font-stack, "VC Nudge Text", "Helvetica Neue", sans-serif);
  cursor: pointer;
  border-radius: var(--btn-border-radius, 8px);
  border: 2px solid var(--button-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover {
  text-decoration: none;
  background-color: var(--background-color);
  color: var(--button-hover-text);
  border: 2px solid var(--button-hover-bg);
}
.btn.active {
  outline: 0;
  background-color: var(--background-color);
  border: 2px solid var(--button-bg);
}
.btn .material-symbols-outlined {
  font-size: 16px;
}

.btn-primary {
  color: var(--button-text);
  background-color: var(--button-bg);
  border-color: var(--button-bg);
}
.btn-primary:hover {
  color: var(--button-hover-text);
  background-color: var(--button-hover-bg);
  border-color: var(--button-hover-bg);
}

.chip {
  align-items: center;
  background: var(--chip-bg);
  color: var(--chip-text);
  border-radius: 5rem;
  display: inline-flex;
  font-size: 90%;
  line-height: 0.8rem;
  margin: 0.1rem;
  overflow: hidden;
  padding: 0.2rem 0.4rem;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  cursor: pointer;
}
.chip.visited::before {
  content: "✓";
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--input-bg);
  background-clip: padding-box;
  border: 1px solid var(--input-border);
  border-radius: var(--input-border-radius, 8px);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  color: var(--text-color);
  background-color: var(--input-bg);
  border-color: var(--input-focus-border);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: var(--input-placeholder);
  opacity: 1;
}

select {
  background-color: transparent;
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 2px solid var(--button-bg);
  border-radius: var(--input-border-radius, 8px);
}

.remove {
  display: none;
  font-size: 14px;
  color: var(--text-color);
  padding: 0px;
  border-radius: 50%;
  margin-right: var(--spacing-xs);
  background-color: var(--background-color);
  cursor: pointer;
  opacity: 0.5;
}

.remove:hover {
  background-color: var(--button-bg);
  opacity: 1;
}

.remove:has(~ .modified) {
  display: inline-block;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: var(--slider-height, 4px);
  background: var(--slider-bg);
  outline: none;
  border-radius: var(--slider-border-radius, 2px);
  margin: var(--spacing-sm, 0.5rem) 0;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--slider-thumb-size, 16px);
  height: var(--slider-thumb-size, 16px);
  background: var(--slider-thumb-color, var(--primary-color, #fb3600));
  cursor: pointer;
  border-radius: 50%;
  border: none;
  transition: background-color var(--transition-duration, 0.3s) ease;
}
input[type=range]::-webkit-slider-thumb:hover {
  background-color: var(--slider-active-color);
}
input[type=range]::-moz-range-thumb {
  width: var(--slider-thumb-size, 16px);
  height: var(--slider-thumb-size, 16px);
  background: var(--slider-thumb-color);
  cursor: pointer;
  border-radius: 50%;
  border: none;
  transition: background-color var(--transition-duration, 0.3s) ease;
}
input[type=range]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px var(--slider-focus-color, rgba(251, 54, 0, 0.2));
}
input[type=range]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 3px var(--slider-focus-color, rgba(251, 54, 0, 0.2));
}
input[type=range]:active::-webkit-slider-thumb, input[type=range].modified::-webkit-slider-thumb {
  background: var(--slider-active-color);
}
input[type=range]:active::-moz-range-thumb, input[type=range].modified::-moz-range-thumb {
  background: var(--slider-active-color);
}
input[type=range]:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
input[type=range]:disabled::-webkit-slider-thumb {
  background: var(--slider-disabled-color, #bcc3ce);
  cursor: not-allowed;
}
input[type=range]:disabled::-moz-range-thumb {
  background: var(--slider-disabled-color, #bcc3ce);
  cursor: not-allowed;
}

input[type=number], input[type=text] {
  background-color: transparent;
  outline: none;
  color: var(--text-color);
  font-size: var(--font-size-small, 0.8em);
  font-weight: 500;
  font-family: var(--idiot-font-stack, "VC Nudge Text", "Helvetica Neue", sans-serif);
  border-radius: var(--input-border-radius, 8px);
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 2px solid var(--button-bg);
}
input[type=number]:hover, input[type=number]:focus, input[type=text]:hover, input[type=text]:focus {
  color: var(--button-hover-text);
  border: 2px solid var(--button-hover-bg);
}

html * {
  box-sizing: border-box;
}

#section__article-app.container {
  max-width: 700px;
  margin: 0 auto;
}
body.tools-visible #section__article-app.container {
  max-width: calc(900px + var(--spacing-lg));
}

.canvas {
  display: flex;
  box-sizing: border-box;
  flex-flow: nowrap;
  width: 100%;
  min-height: 100vh;
  transition: all 0.25s ease-in-out;
  overflow-x: hidden;
}
.canvas .off-canvas-sidebar-fonts {
  flex: 0 0 auto;
  max-width: 0px;
  min-width: 0px;
  opacity: 0;
  overflow: hidden;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  max-height: 100vh;
  transition: width 0.25s ease;
  z-index: 200;
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) var(--background-color);
  overflow-y: auto;
  height: 100vh;
}
body.tools-visible .canvas .off-canvas-sidebar-fonts {
  min-width: 11.5rem;
  max-width: auto;
  overflow-y: auto;
  opacity: 1;
}
@media (max-width: 960px) {
  body.tools-visible .canvas .off-canvas-sidebar-fonts {
    max-width: 50px;
  }
}
.canvas .off-canvas-content {
  flex: 1;
  min-width: 0;
  overflow-y: auto;
  height: 100vh;
  transition: all 0.25s ease;
  min-height: 100%;
}
.canvas .off-canvas-sidebar-settings {
  flex: 0 0 auto;
  max-width: 0px;
  min-width: 0px;
  overflow: hidden;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  transition: transform 0.25s ease;
  z-index: 201;
  scrollbar-color: var(--border-color) var(--background-color);
  overflow-y: auto;
  height: 100vh;
}
body.settings-visible .canvas .off-canvas-sidebar-settings {
  overflow-y: auto;
  min-width: 11.5rem;
  max-width: auto;
}

.section__header-app {
  background: var(--header-bg, #061117);
  color: var(--header-color, #4d494d);
  position: sticky;
  top: 0;
  z-index: var(--z-index-header, 100);
  padding-bottom: 0;
}

.section__footer-app a {
  color: var(--footer-link-color, #e47f7d);
}

.tab {
  border-bottom: 0.05rem solid var(--border-color);
  display: flex;
  flex-wrap: none;
  list-style: none;
  margin: 0px;
}
.tab.tab-block .tab-item {
  flex: 1 0 auto;
  text-align: center;
}
.tab .tab-item {
  margin-top: 0;
}
.tab .tab-item .remove {
  opacity: 0;
  display: inline-block;
  margin-right: 0;
  vertical-align: middle;
  transition: opacity 0.2s ease;
}
.tab .tab-item a {
  border-bottom: 2px solid transparent;
  color: var(--text-color);
  margin: 0;
  margin-bottom: -2px;
  padding: var(--idiot-unit) calc(var(--idiot-unit) * 2);
  text-decoration: none;
  transition: all var(--transition-duration, 0.3s) ease;
}
.tab .tab-item a:focus, .tab .tab-item a:hover {
  color: var(--link-color);
}
.tab .tab-item a:focus .tab-remove, .tab .tab-item a:hover .tab-remove {
  opacity: 1;
}
.tab .tab-item.active a {
  border-bottom-color: var(--link-color);
  color: var(--link-color);
}

#section__header-stage-buttons {
  background-color: var(--background-color);
  border-bottom: 1px solid var(--border-color);
}
#section__header-stage-buttons .tab-item .stage-button {
  font-weight: var(--font-weight-normal, 400);
  font-size: var(--base-font-size);
}
#section__header-stage-buttons .tab-item.active .stage-button {
  font-weight: var(--font-weight-bold, 700);
}

.drag-drop-area {
  height: 92vh;
  border: 2px dashed var(--button-bg);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
}

.drag-drop-area.highlight {
  border: 2px solid var(--border-color);
  background-color: var(--input-bg);
}

.drag-drop-area p {
  margin: var(--spacing-sm) 0;
  color: var(--text-color);
}

.file-input-label {
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--spacing-xs);
  background-color: var(--input-bg);
  border: 2px solid var(--input-bg);
}

.file-input-label:hover {
  background-color: var(--input-bg);
  border: 2px solid var(--border-color);
}

.file-input {
  display: none;
}

.drag-drop-area img {
  max-width: 50px;
  max-height: 50px;
  margin: 10px;
  border-radius: 5px;
}

.item {
  margin-bottom: var(--spacing-lg, 2rem);
  opacity: 0;
  transform-origin: top center;
  transition: opacity var(--transition-duration, 3s) ease-in-out, transform var(--transition-duration, 1s) ease-in-out;
}
body.loaded .item {
  opacity: 1;
  transform: scaleY(1) translateY(0);
}
.item__container {
  display: flex;
  align-items: stretch;
  gap: 0px;
  transition: all 0.7s ease-in-out;
}
.tools-visible .item__container {
  gap: var(--spacing-lg, 1.5rem);
}
.item__sliders {
  flex: 0 0 auto;
  min-width: 0px;
  width: 0px;
  transition: transform 0.4s ease, opacity 0.8s ease, width 0.8s ease;
  transform: translateX(-100%);
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.tools-visible .item__sliders {
  transform: translateX(0);
  width: 200px;
  min-width: 160px;
  opacity: 1;
  height: auto;
}
.item__sliders-wrapper {
  position: sticky;
  top: var(--sticky-top, 1rem);
}
.item__sliders-wrapper label {
  display: inline-block;
  margin-bottom: var(--spacing-xs, 0.25rem);
}
.item__sliders-wrapper .t__right {
  float: right;
}
.item__sliders-wrapper .slider {
  width: 100%;
  margin-bottom: var(--spacing-sm, 0.5rem);
}
.item__sliders-wrapper .btn__wrapper {
  margin-bottom: var(--spacing-sm, 0.5rem);
}
.item__proof {
  display: flex;
  width: 700px;
  max-width: 100%;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  max-width: 100%;
  background: var(--card-bg, #fff);
  border-radius: var(--card-border-radius, 2px);
  box-shadow: var(--card-shadow);
  padding: var(--spacing-xl);
  position: relative;
  gap: var(--spacing-xxs, 0.25rem);
  height: auto;
}
.item__proof.locked-dimensions {
  display: flex;
  min-width: 700px !important;
  max-width: 700px !important;
  width: 700px !important;
}
.item__proof.ratio-letter {
  aspect-ratio: 8.5/11;
}
.item__proof.ratio-a4 {
  aspect-ratio: 7/10;
}
.item__proof .btn.remove-item-this {
  position: absolute;
  right: var(--spacing-md, 1.5rem);
  top: var(--spacing-md, 1.5rem);
  color: var(--text-color, #66758c);
  font-size: 30px;
  opacity: 0.5;
  line-height: 1;
  align-items: center;
  transition: opacity var(--transition-duration, 0.3s) ease;
}
.item__proof .btn.remove-item-this:hover {
  opacity: 1;
}
.item__proof h6 {
  margin-bottom: var(--spacing-xs, 0.25rem);
}
.item__proof .testarea-values, .item__proof .h6 {
  display: block;
  margin-bottom: var(--spacing-sm, 0.5rem);
  font-size: var(--font-size-small, 0.8em);
  color: var(--text-color-muted, #999);
  margin: 0;
}
.item__proof .testarea-container {
  flex: 1;
  min-height: 0;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.item__proof .testarea {
  width: 100%;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border: none;
  outline: none;
  background: transparent;
  color: var(--text-color);
}
.item__proof .item__footer {
  flex-grow: 0;
  display: flex;
  color: var(--text-color-muted, #999);
  font-size: var(--font-size-small, 0.8em);
  width: 100%;
  display: none;
}
body.show-font-details .item__proof .item__footer {
  display: flex;
}
.item .btn.add-item-above {
  display: block;
  width: 100%;
  background: var(--chip-bg, #eef0f3);
  max-width: none;
  opacity: 0;
  transition: opacity var(--transition-duration, 0.3s) ease-in-out;
  color: var(--text-color, #66758c) !important;
  letter-spacing: 0.03em;
}
.item .btn.add-item-above:hover {
  opacity: 1;
  transition-delay: 0.1s;
}

.btn__setfont {
  display: block;
  margin-top: var(--spacing-xs, 0.25rem);
}
.btn__setfont input[type=checkbox] {
  margin-right: var(--spacing-xs, 0.25rem);
}

@media (max-width: 768px) {
  .item__container {
    flex-direction: column;
  }
  .item__sliders {
    width: 100%;
    margin-bottom: var(--spacing-md, 1.5rem);
  }
  .item__sliders-wrapper {
    position: static;
  }
}
body {
  font-family: var(--body-font-family, "VC Nudge Text", "Helvetica Neue", sans-serif);
  font-size: var(--body-font-size, 0.6rem);
  font-weight: 500;
  line-height: var(--body-line-height, 1.5);
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font-family, "VC Nudge Text", "Helvetica Neue", sans-serif);
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--heading-color);
}

h1 {
  font-size: var(--h1-font-size, 2rem);
}

h2 {
  font-size: var(--h2-font-size, 1.6rem);
}

h3 {
  font-size: var(--h3-font-size, 1.4rem);
}

h4 {
  font-size: var(--h4-font-size, 1.2rem);
}

h5 {
  font-size: var(--h5-font-size, 1rem);
}

h6 {
  font-size: var(--h6-font-size, 0.8rem);
}

.t__size-xxl {
  font-size: var(--font-size-xxl, 140pt);
  line-height: 1.1;
}

.t__size-xl {
  font-size: var(--font-size-xl, 100pt);
  line-height: 1.2;
}

.t__size-l {
  font-size: var(--font-size-l, 84pt);
  line-height: 1.2;
}

.t__size-m {
  font-size: var(--font-size-m, 56pt);
  line-height: 1.3;
}

.t__size-s {
  font-size: var(--font-size-s, 28pt);
  line-height: 1.4;
}

.t__size-xs {
  font-size: var(--font-size-xs, 14pt);
  line-height: 1.4;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.lead {
  font-size: var(--lead-font-size, 1.25rem);
  font-weight: 400;
}

.text-small {
  color: var(--text-color-light);
  font-size: var(--font-size-small, 0.8em);
  text-transform: uppercase;
}

.text-large {
  font-size: var(--large-font-size, 1.25em);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-weight-light {
  font-weight: 400;
}

.font-weight-normal {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-success {
  color: var(--success-color);
}

.text-warning {
  color: var(--warning-color);
}

.text-danger {
  color: var(--error-color);
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex, .flex {
  display: flex;
}

.d-flex-grow, .flex-grow-1 {
  flex-grow: 1;
}

.d-none {
  display: none;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.t__left, .text-left {
  text-align: left;
}

.t__center, .text-center {
  text-align: center;
}

.t__right, .text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

.small {
  font-size: var(--font-size-small, 0.8em);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.g-0 {
  gap: 0 !important;
}

.gt-0,
.gy-0 {
  gap-top: 0 !important;
}

.gr-0,
.gx-0 {
  gap-right: 0 !important;
}

.gb-0,
.gy-0 {
  gap-bottom: 0 !important;
}

.gl-0,
.gx-0 {
  gap-left: 0 !important;
}

.g-1 {
  gap: 0.25rem !important;
}

.gt-1,
.gy-1 {
  gap-top: 0.25rem !important;
}

.gr-1,
.gx-1 {
  gap-right: 0.25rem !important;
}

.gb-1,
.gy-1 {
  gap-bottom: 0.25rem !important;
}

.gl-1,
.gx-1 {
  gap-left: 0.25rem !important;
}

.g-2 {
  gap: 0.5rem !important;
}

.gt-2,
.gy-2 {
  gap-top: 0.5rem !important;
}

.gr-2,
.gx-2 {
  gap-right: 0.5rem !important;
}

.gb-2,
.gy-2 {
  gap-bottom: 0.5rem !important;
}

.gl-2,
.gx-2 {
  gap-left: 0.5rem !important;
}

.g-3 {
  gap: 1rem !important;
}

.gt-3,
.gy-3 {
  gap-top: 1rem !important;
}

.gr-3,
.gx-3 {
  gap-right: 1rem !important;
}

.gb-3,
.gy-3 {
  gap-bottom: 1rem !important;
}

.gl-3,
.gx-3 {
  gap-left: 1rem !important;
}

.g-4 {
  gap: 1.5rem !important;
}

.gt-4,
.gy-4 {
  gap-top: 1.5rem !important;
}

.gr-4,
.gx-4 {
  gap-right: 1.5rem !important;
}

.gb-4,
.gy-4 {
  gap-bottom: 1.5rem !important;
}

.gl-4,
.gx-4 {
  gap-left: 1.5rem !important;
}

.g-5 {
  gap: 3rem !important;
}

.gt-5,
.gy-5 {
  gap-top: 3rem !important;
}

.gr-5,
.gx-5 {
  gap-right: 3rem !important;
}

.gb-5,
.gy-5 {
  gap-bottom: 3rem !important;
}

.gl-5,
.gx-5 {
  gap-left: 3rem !important;
}

.g-6 {
  gap: 4rem !important;
}

.gt-6,
.gy-6 {
  gap-top: 4rem !important;
}

.gr-6,
.gx-6 {
  gap-right: 4rem !important;
}

.gb-6,
.gy-6 {
  gap-bottom: 4rem !important;
}

.gl-6,
.gx-6 {
  gap-left: 4rem !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-success {
  color: var(--success-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-warning {
  color: var(--warning-color);
}

.text-info {
  color: var(--info-color);
}

.text-light {
  color: var(--light-color);
}

.text-dark {
  color: var(--dark-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-success {
  background-color: var(--success-color);
}

.bg-danger {
  background-color: var(--danger-color);
}

.bg-warning {
  background-color: var(--warning-color);
}

.bg-info {
  background-color: var(--info-color);
}

.bg-light {
  background-color: var(--light-color);
}

.bg-dark {
  background-color: var(--dark-color);
}

.border {
  border: 1px solid var(--border-color);
}

.border-top {
  border-top: 1px solid var(--border-color);
}

.border-right {
  border-right: 1px solid var(--border-color);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.border-left {
  border-left: 1px solid var(--border-color);
}

.border-0 {
  border: 0;
}

.rounded {
  border-radius: var(--border-radius);
}

.rounded-top {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.rounded-right {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.rounded-bottom {
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.rounded-left {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.rounded-circle {
  border-radius: 50%;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

@media (max-width: 959px) {
  .item__sliders,
  .section__header-tools {
    display: none !important;
  }
}
@media print {
  * {
    background: transparent !important;
    color: #000;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  body {
    margin: 0;
    padding: 0;
    line-height: 1.4;
    font-size: 12pt;
    background: none;
  }
  .canvas .off-canvas-content,
  .canvas,
  body,
  html {
    height: auto !important;
    width: 100% !important;
    overflow: visible !important;
  }
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100% !important;
    max-width: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .row,
  .col,
  [class^=col-] {
    width: 100% !important;
    max-width: none !important;
    flex: 0 0 100% !important;
  }
  .item {
    break-inside: avoid;
    page-break-inside: avoid;
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .item__proof {
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    transform: none !important;
  }
  #section__header-stage-buttons,
  .navbar,
  .item__sliders,
  #section__footer-app,
  button,
  .btn,
  .no-print {
    display: none !important;
  }
  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  h1 {
    font-size: 24pt;
  }
  h2 {
    font-size: 20pt;
  }
  h3 {
    font-size: 16pt;
  }
  h4, h5, h6 {
    font-size: 10pt;
    margin-bottom: 0;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h6, .item__proof .testarea-values, .proof-footer {
    font-size: 8pt !important;
    color: #999 !important;
  }
  a, a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  thead {
    display: table-header-group;
  }
  tr, img {
    page-break-inside: avoid;
  }
  @page {
    padding: 4mm 5mm;
  }
  .item:last-child {
    page-break-after: auto;
  }
}