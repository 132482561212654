// _media-queries.scss


// Custom media query for hiding sliders on smaller screens
@media (max-width: #{$breakpoint-lg - 1px}) {
  .item__sliders,
  .section__header-tools {
    display: none !important;
  }
}

// Add more media queries as needed