// _items.scss

.item {
  margin-bottom: var(--spacing-lg, 2rem);
  opacity: 0;
  transform-origin: top center;
  transition: opacity var(--transition-duration, 3s) ease-in-out,
              transform var(--transition-duration, 1s) ease-in-out;

  body.loaded & {
    opacity: 1;
    transform: scaleY(1) translateY(0);
  }

  &__container {
    display: flex;
    align-items: stretch;
    gap: 0px;
    transition: all 0.7s ease-in-out;
    .tools-visible & {
      gap: var(--spacing-lg, 1.5rem);
    }
  }

  &__sliders {
    flex: 0 0 auto;
    min-width: 0px;
    width: 0px;
    transition: transform 0.4s ease, opacity 0.8s ease, width 0.8s ease;
    transform: translateX(-100%);
    opacity: 0;
    height: 0;
    overflow: hidden;

    .tools-visible & {
      transform: translateX(0);
      width: 200px;
      min-width: 160px;
      opacity: 1;
      height: auto;
    }

    &-wrapper {
      position: sticky;
      top: var(--sticky-top, 1rem);

      label {
        display: inline-block;
        margin-bottom: var(--spacing-xs, 0.25rem);
      }

      .t__right {
        float: right;
      }

      .slider {
        width: 100%;
        margin-bottom: var(--spacing-sm, 0.5rem);
      }

      .btn__wrapper {
        margin-bottom: var(--spacing-sm, 0.5rem);
      }
    }
  }

  &__proof {
    display: flex;
    width: 700px;
    max-width: 100%;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0; // Crucial for text wrapping in flex child
    max-width: 100%;
    background: var(--card-bg, #fff);
    border-radius: var(--card-border-radius, 2px);
    box-shadow: var(--card-shadow);
    padding: var(--spacing-xl);
    position: relative;
    gap: var(--spacing-xxs, 0.25rem);
    height: auto;

    &.locked-dimensions {
      display: flex;
      min-width: 700px !important;
      max-width: 700px !important;
      width: 700px !important;
    }

    // USA Letter (8.5:11)
    &.ratio-letter {
      aspect-ratio: 8.5/11;
    }

    // ISO A4 (7:10)
    &.ratio-a4 {
      aspect-ratio: 7/10;
    }

    .btn.remove-item-this {
      position: absolute;
      right: var(--spacing-md, 1.5rem);
      top: var(--spacing-md, 1.5rem);
      color: var(--text-color, #66758c);
      font-size: 30px;
      opacity: 0.5;
      line-height: 1;
      align-items: center;
      transition: opacity var(--transition-duration, 0.3s) ease;

      &:hover {
        opacity: 1;
      }
    }

    h6 {
      margin-bottom: var(--spacing-xs, 0.25rem);
    }

    .testarea-values, .h6 {
      display: block;
      margin-bottom: var(--spacing-sm, 0.5rem);
      font-size: var(--font-size-small, 0.8em);
      color: var(--text-color-muted, #999);
      margin: 0;
    }
    .testarea-container {
      flex: 1; // Allow growing
      min-height: 0; // Allow shrinking
      overflow: hidden; // Hide overflow
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .testarea {
      width: 100%;
      max-width: 100%;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      border: none;
      outline: none;
      background: transparent;
      color: var(--text-color);
    }
    .item__footer {
      flex-grow: 0;
      display: flex;
      color: var(--text-color-muted, #999);
      font-size: var(--font-size-small, 0.8em);
      width: 100%;
      display: none;
      body.show-font-details & {
        display: flex;
      }
    }

  }

  .btn.add-item-above {
    display: block;
    width: 100%;
    background: var(--chip-bg, #eef0f3);
    max-width: none;
    opacity: 0;
    transition: opacity var(--transition-duration, 0.3s) ease-in-out;
    color: var(--text-color, #66758c) !important;
    letter-spacing: 0.03em;

    &:hover {
      opacity: 1;
      transition-delay: 0.1s;
    }
  }
}

.btn__setfont {
  display: block;
  margin-top: var(--spacing-xs, 0.25rem);

  input[type="checkbox"] {
    margin-right: var(--spacing-xs, 0.25rem);
  }
}

// Responsive styles
@media (max-width: 768px) {
  .item {
    &__container {
      flex-direction: column;
    }

    &__sliders {
      width: 100%;
      margin-bottom: var(--spacing-md, 1.5rem);

      &-wrapper {
        position: static;
      }
    }
  }
}